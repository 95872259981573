exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-potwierdzenie-js": () => import("./../../../src/pages/kontakt-potwierdzenie.js" /* webpackChunkName: "component---src-pages-kontakt-potwierdzenie-js" */),
  "component---src-pages-polityka-cookies-js": () => import("./../../../src/pages/polityka-cookies.js" /* webpackChunkName: "component---src-pages-polityka-cookies-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-poradnik-potwierdzenie-js": () => import("./../../../src/pages/poradnik-potwierdzenie.js" /* webpackChunkName: "component---src-pages-poradnik-potwierdzenie-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

